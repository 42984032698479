

/**
 * VARIABLES:
 * **********
 */
 :root {
  --ruby: #e22653;
  --grey: #999;
  --dark-grey: #666;
  --light-grey: #ccc;
  --cream: #f9f7ed;
  --transparent-white: #ffffffcc;
  --transition: all ease-out 300ms;
  --shadow: 0 1px 5px var(--dark-grey);
  --hover-opacity: 0.7;
  --stage-padding: 8px;
  --panels-width: 350px;
  --border-radius: 3px;
}

/**
 * BASE STYLES:
 * ************
 */
html, body {
  background: white;
  font-size: 0.9em;
  height: 100%;
}
h2 {
  font-size: 1.3em;
  margin: 0;
}
h2 > * {
  vertical-align: text-top;
}
h3 {
  margin: 4px 0px;
}
a {
  color: black !important;
}
a:hover {
  opacity: var(--hover-opacity);
}

/**
 * LAYOUT:
 * *******
 */
#root {
  display: flex;
  flex: 1;
  overflow: hidden;
}
body {
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
}

::-webkit-scrollbar {
  width: 5px;
}
::-webkit-scrollbar-track {
  background: transparent;
}
::-webkit-scrollbar-thumb {
  background-color: var(--grey);
  border: transparent;
}
